<template>
  <v-container>
    <v-row>
      <v-col class="text-center mt-5">
        <h1>{{ $t("title.agreement") }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0 mt-5">
        <v-card id="agreement" class="pa-1">
          <embed
            src="../agreement/agreement.pdf"
            width="100%"
            height="100%"
            type="application/pdf"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center mt-7">
        <v-btn
          elevation="1"
          color="privat"
          height="39px"
          min-width="300px"
          dark
          @click.native="sendAgreement"
          :loading="loading"
        >
          {{ $t("action.agreement") }}
        </v-btn>
      </v-col>
    </v-row>
    <base-snackbar
      :props="snack"
      :show="show"
      @close="show = false"
    ></base-snackbar>
  </v-container>
</template>

<script>
import showSnack from "@/mixins/showSnack.mixins";
import { agreement } from "@/services/auth.service";
import errorHandler from "@/utils/errorHandler";

export default {
  name: "agreement",
  mixins: [showSnack],
  data() {
    return {
      loading: false
    };
  },
  methods: {
    sendAgreement() {
      this.loading = true;
      agreement()
        .then(resp => {
          let user = JSON.stringify(resp.data);

          localStorage.setItem("info", user);
          this.$store.commit("userInfo_success", resp.data);
        })
        .then(() => {
          this.$router.push("/orders");
        })
        .catch(err => {
          let errorCode = errorHandler(err, "sendAgreement");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch sendAgreement");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
#agreement {
  height: 70vh;
}
</style>
